import React, { useReducer } from 'react';
import jwt_decode from 'jwt-decode';
import _get from 'lodash/get';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const savedToken =
  typeof window !== `undefined` ? localStorage.getItem('access_token') : '';

const savedTokenExpiry =
  typeof window !== `undefined`
    ? localStorage.getItem('access_token_expiry')
    : '';

// Check if saved token not expired
const isAuthenticatedOnload =
  !!savedToken &&
  !!savedTokenExpiry &&
  Number(savedTokenExpiry) > Math.round(new Date().getTime() / 1000);

const initialState = {
  authenticated: isAuthenticatedOnload,
  access_token: savedToken,
  refresh_token:
    typeof window !== `undefined` ? localStorage.getItem('refresh_token') : '',
  access_token_expiry:
    typeof window !== `undefined`
      ? localStorage.getItem('access_token_expiry')
      : 0,
  refresh_token_expiry:
    typeof window !== `undefined`
      ? localStorage.getItem('refresh_token_expiry')
      : 0,
  adfs_token:
    typeof window !== `undefined`
      ? localStorage.getItem('ad_access_token')
      : '',
  docsAst: {
    share: {},
    rent: {},
    sixtplus: {},
  },
  fullAst: {
    share: {},
    rent: {},
    sixtplus: {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_SUCCESS': {
      return {
        ...state,
        authenticated: true,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        access_token_expiry: _get(
          jwt_decode(action.payload.access_token),
          'exp',
          0,
        ),
        refresh_token_expiry: _get(
          jwt_decode(action.payload.refresh_token),
          'exp',
          0,
        ),
      };
    }

    case 'AUTH_RESET': {
      return {
        ...state,
        authenticated: false,
        access_token: '',
        refresh_token: '',
        access_token_expiry: 0,
        refresh_token_expiry: 0,
        adfs_token: '',
        docsAst: {},
        fullAst: {},
      };
    }

    case 'ADFS_AUTH_SUCCESS': {
      return {
        ...state,
        authenticated: true,
        adfs_token: action.payload,
      };
    }

    case 'DOCS_FETCHED': {
      return {
        ...state,
        docsAst: {
          ...state.docsAst,
          [action.payload.section]: action.payload.finalAST,
        },
        fullAst: {
          ...state.fullAst,
          [action.payload.section]: action.payload.fullAst,
        },
      };
    }

    case 'PERMISSION_ERROR': {
      return {
        ...state,
        isPermissionDenied: true,
      };
    }

    case 'RESET_PERMISSION_ERROR': {
      return {
        ...state,
        isPermissionDenied: false,
      };
    }

    default: {
      throw new Error('Bad Action Type');
    }
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
